import React from "react"

import Menu from "./Menu"
import Footer from "./Footer"
import FooterAlt from "../components/FooterAlt"

const Layout = ({
  page,
  showMenu = false,
  showFooter,
  children,
  footerTitle = "",
}) => {
  return (
    <>
      <div
        className="has-background-black-bis" //this is our body background covering the drop down menu
        style={{
          zIndex: "10",
          position: "relative",
          minHeight: "100vh",
        }}
      >
        {showMenu ? <Menu /> : ""}

        {children}
      </div>
      {showFooter ? <Footer title={footerTitle} /> : <FooterAlt />}
    </>
  )
}

export default Layout
