/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

const Bio = ({ author: { node } }) => {
  const avatarUrl = node?.avatar?.url
  return (
    <div className="blog-post__bio bio">
      {avatarUrl && (
        <img
          alt={node?.name || ``}
          className="bio-avatar blog-post__bio-avatar"
          src={avatarUrl}
        />
      )}
      {node?.name && (
        <p className="blog-post__bio-author">
          Written by{" "}
          <strong className="blog-post__bio-link">{node?.name}</strong>
        </p>
      )}
    </div>
  )
}

export default Bio
