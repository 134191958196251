import React from "react"
import { Link, graphql } from "gatsby"
//import Image from "gatsby-image"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import BackroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"

import Bio from "../components/bio"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import Menu from "../components/Menu"

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  let featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``,
  }

  return (
    <Layout showFooter={true}>
      <Seo seo={post.seo} />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="blog-post__header">
          <Menu />
          <div className="blog-post__header-image--container">
            <GatsbyImage
              image={
                post.featuredImage?.node?.localFile?.childImageSharp
                  ?.gatsbyImageData
              }
              className="blog-post__image"
              alt={featuredImage.alt}
              style={{ marginBottom: 50 }}
            />
            <div className="blog-post__image-overlay"></div>
          </div>
          <div className="blog-post__header-container">
            <h1 className="blog-post__title" itemProp="headline">
              {parse(post.title)}
            </h1>

            <p className="blog-post__date">{post.date}</p>
          </div>
        </header>

        {!!post.content && (
          <section className="blog-post__container" itemProp="articleBody">
            {parse(post.content)}
          </section>
        )}

        <hr />

        <footer>
          <Bio author={post?.author} />
        </footer>
      </article>

      <nav className="blog-post-nav">
        <div className="other-articles__container">
          {previous?.uri !== "/" && (
            <div className="blog-post__previous blog-post__read-article">
              <div className="blog-post__image-container">
                <GatsbyImage
                  image={
                    previous.featuredImage?.node?.localFile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  className="blog-post--gatsby-image"
                  alt={featuredImage.alt}
                  style={{
                    maxHeight: "464px",
                  }}
                />
                <div className="blog-post__image-overlay"></div>
              </div>
              <Link to={previous.uri} rel="prev">
                <div className="blog-post__other-article-container previous-container">
                  <div className="previous--next_arrow">
                    {" "}
                    <svg
                      width="34"
                      height="18"
                      viewBox="0 0 34 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.224724 8.57393L7.95182 0.846772C8.22939 0.522649 8.7172 0.484865 9.04132 0.762504C9.36545 1.04008 9.40323 1.52789 9.12559 1.85201C9.0997 1.88223 9.07154 1.91045 9.04132 1.93628L2.63556 8.34981H33.2273C33.654 8.34981 34 8.69578 34 9.12257C34 9.54935 33.654 9.89526 33.2273 9.89526H2.63556L9.04132 16.301C9.36545 16.5786 9.40323 17.0664 9.12559 17.3905C8.84795 17.7147 8.36021 17.7524 8.03608 17.4748C8.00587 17.4489 7.97765 17.4207 7.95182 17.3905L0.224655 9.66337C-0.0748978 9.36209 -0.0748978 8.87534 0.224724 8.57393Z"
                        fill="#FFF200"
                      />
                    </svg>
                  </div>
                  <div className="previous--next_text">Previous</div>
                  <div className="previous--next_title">
                    {parse(previous.title)}
                  </div>
                </div>
              </Link>
            </div>
          )}

          {next && next?.uri !== "/" && (
            <div className="blog-post__next blog-post__read-article">
              <div className="blog-post__image-container">
                <GatsbyImage
                  image={
                    next.featuredImage?.node?.localFile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  className="blog-post--gatsby-image"
                  alt={featuredImage.alt}
                  style={{
                    maxHeight: "464px",
                  }}
                />
                <div className="blog-post__image-overlay"></div>
              </div>
              <Link to={next.uri} rel="next">
                <div className="blog-post__other-article-container next-container">
                  <div className="previous--next_arrow">
                    {" "}
                    <svg
                      className="next-arrow"
                      width="34"
                      height="18"
                      viewBox="0 0 34 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.224724 8.57393L7.95182 0.846772C8.22939 0.522649 8.7172 0.484865 9.04132 0.762504C9.36545 1.04008 9.40323 1.52789 9.12559 1.85201C9.0997 1.88223 9.07154 1.91045 9.04132 1.93628L2.63556 8.34981H33.2273C33.654 8.34981 34 8.69578 34 9.12257C34 9.54935 33.654 9.89526 33.2273 9.89526H2.63556L9.04132 16.301C9.36545 16.5786 9.40323 17.0664 9.12559 17.3905C8.84795 17.7147 8.36021 17.7524 8.03608 17.4748C8.00587 17.4489 7.97765 17.4207 7.95182 17.3905L0.224655 9.66337C-0.0748978 9.36209 -0.0748978 8.87534 0.224724 8.57393Z"
                        fill="#FFF200"
                      />
                    </svg>
                  </div>
                  <div className="previous--next_text">Next</div>
                  <div className="previous--next_title">
                    {parse(next.title)}
                  </div>
                </div>
              </Link>
            </div>
          )}
        </div>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      author {
        node {
          avatar {
            url
            foundAvatar
          }
          name
          username
          lastName
          firstName
        }
      }
      seo {
        title
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            url
          }
        }
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
