import React from "react"
import SocialMediaLink from "./SocialMediaLink"
import Section from "./Section"
import { Link } from "gatsby"
const FooterAlt = () => {
  return (
    <>
      <Section additionalClasses="section has-background-black-bis alt-footer is-hidden-desktop">
        <div className="container">
          <div className="columns is-mobile">
            <div className="column has-text-centered">
              {/* <SocialMediaLink type="instagram" /> */}
            </div>
            <div className="column has-text-centered">
              <SocialMediaLink type="linkedin" />
            </div>
            <div className="column has-text-centered">
              {/*   <SocialMediaLink type="twitter" /> */}
            </div>
          </div>
          <div className="column has-text-centered">
            &copy; Indie Ridge | <Link to="/privacy-policy">Privacy</Link>
          </div>
        </div>
      </Section>

      <Section additionalClasses="section has-background-black-bis alt-footer is-hidden-mobile">
        <div className="container">
          <div className="columns">
            <div className="column is-9">
              <div className="column has-text-left">
                &copy; Indie Ridge | <Link to="/privacy-policy">Privacy</Link>
              </div>
            </div>

            <div className="is-1 column has-text-right">
              {/* <SocialMediaLink type="instagram" />*/}
            </div>
            <div className="is-1 column has-text-right">
              <SocialMediaLink type="linkedin" />
            </div>
            <div className="is-1 column has-text-right">
              {/* <SocialMediaLink type="twitter" />*/}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default FooterAlt
