import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Menu from "./Menu"
import SocialMediaLink from "./SocialMediaLink"
import parse from "html-react-parser"

const Footer = ({ title }) => {
  const [startAnimation, setStartAnimation] = useState("")
  const [menuTheme, setMenuTheme] = useState("dark")
  const [footerHasAnimated, setFooterHasAnimated] = useState("")
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    const NF = 30,
      footerSectionStyle = document.getElementById("is-footer-section").style

    let rID = null
    let f = 30
    let typ = 0

    const stopAni = () => {
      cancelAnimationFrame(rID)
      rID = null
      setStartAnimation(false)
      setFooterHasAnimated(true)
    }

    const update = () => {
      f = f - 1
      let k = f / NF
      footerSectionStyle.setProperty("--stop", `${+(k * 100).toFixed(2)}%`)

      if (f === 0) {
        f = 0
        // S.setProperty("--gc1", `var(--c${typ})`)
        typ = 1 - typ
        // S.setProperty("--gc0", `var(--c${typ})`)
        footerSectionStyle.setProperty("--stop", `0%`)
        setMenuTheme("light")
        stopAni()

        return
      }

      rID = requestAnimationFrame(update)
    }

    if (startAnimation) {
      setMenuTheme("light")

      footerSectionStyle.setProperty(
        "background",
        `radial-gradient(
        circle at var(--x, 0) var(--y, 0),
        var(--gc0, var(--c0)) var(--stop, 0%),
        var(--gc1, var(--c1)) 0
      )`
      )

      const rect = document
        .getElementById("centredButton")
        .getBoundingClientRect()

      let yPos = rect.top + rect.height / 2

      footerSectionStyle.setProperty("--x", "50%")
      footerSectionStyle.setProperty("--y", `${yPos}px`)

      update()
    }
  }, [startAnimation])

  return (
    <footer id="footer" style={{}}>
      <section
        id="is-footer-section"
        className="hero is-hero  is-primary is-fullheight is-footer-section"
        style={{ width: "100%" }}
      >
        {/* Hero head: will stick at the top */}
        <div className="hero-head">
          <div className="container">
            {menuTheme === "dark" ? (
              <Menu menuLocation="footer" menuStyle="dark" />
            ) : (
              <Menu menuLocation="footer" menuStyle="light" />
            )}
          </div>
        </div>

        {/* Hero content: will be in the middle */}

        <div className="hero-body">
          <div className="container has-text-centered">
            <h1
              className={`is-size-2 is-size-3-mobile has-text-weight-bold ${
                menuTheme === "dark" ? "has-text-black" : "has-text-white"
              }`}
            >
              {!title ? "Have a project in mind?" : parse(title)}
            </h1>
            {!title && (
              <h2
                className={`is-size-3 is-size-4-mobile mt-4 has-text-weight-bold ${
                  menuTheme === "dark" ? "has-text-black" : "has-text-white"
                }`}
              >
                We would love to hear about it.
              </h2>
            )}

            <h2
              id="header2x"
              className="is-size-3 is-size-4-mobile mt-4 has-text-weight-semibold has-text-black-biz"
            >
              &nbsp;
            </h2>
            <Link
              to="/contact"
              style={{
                width: "20%",
                minWidth: "400px",
                margin: "0px auto",
                marginTop: "100px",
              }}
            >
              <div
                id="centredButton"
                className="is-hidden-touch footer-cta-circle has-text-centered"
                onMouseOver={
                  footerHasAnimated
                    ? null
                    : () => {
                        setStartAnimation(true)
                      }
                }
              >
                <div className="circle-txt">
                  <h2 className="is-size-4 is-size-3-mobile has-text-black has-text-weight-semibold">
                    TALK TO US
                  </h2>
                </div>
              </div>

              <div className="is-hidden-desktop footer-cta-circle has-text-centered">
                <div className="circle-txt">
                  <h2 className="is-size-3 is-size-3-mobile has-text-black has-text-weight-semibold">
                    TALK TO US
                  </h2>
                </div>
              </div>
            </Link>
          </div>
        </div>

        {/* Hero footer: will stick at the bottom */}
        <div className="hero-foot" style={{ paddingBottom: "50px" }}>
          <div className="container ">
            <div className="columns is-vcentered">
              <div className="column is-6 is-hidden-touch">
                <div className="columns">
                  <div
                    className={`column   ${
                      menuTheme === "dark" ? "" : "has-text-white"
                    }`}
                  >
                    &copy; Indie Ridge {currentYear} |{" "}
                    <Link to="/privacy-policy">Privacy</Link>
                  </div>
                </div>
              </div>
              <div className="column is-half-tablet ">
                <div className="columns is-half-tablet has-text-centered-mobile is-socila">
                  {/*
                  <div className="column has-text-right has-text-centered-mobile">
                    <SocialMediaLink type="instagram" theme={menuTheme} />
                  </div>
                  */}
                  <div className="column has-text-right has-text-centered-mobile">
                    <SocialMediaLink type="linkedin" theme={menuTheme} />
                  </div>
                  {/*
                  <div className="column has-text-right has-text-centered-mobile">
                    <SocialMediaLink type="twitter" theme={menuTheme} />
                  </div>
                */}
                </div>
              </div>
              <div
                className={`column is-hidden-desktop is-size-6-mobile has-text-centered-mobile is-mobile-copyright ${
                  menuTheme === "dark" ? "" : "has-text-white"
                }`}
              >
                &copy; Indie Ridge {currentYear} |{" "}
                <Link to="/privacy-policy">Privacy</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  )
}

export default Footer
