import React from "react"
import "aos/dist/aos.css"
const Section = ({
  children,
  style,
  additionalClasses,
  theme = "dark",
  isFullWidth,
  dataAos = "",
}) => {
  const sectionTheme =
    theme === "light"
      ? "has-background-white has-text-black-bis"
      : "has-background-bis has-text-white"
  const sectionClass = additionalClasses
  return (
    <div
      className={`section ${sectionTheme}  ${sectionClass} `}
      style={style}
      data-aos={`${dataAos}`}
    >
      {isFullWidth ? (
        <>{children}</>
      ) : (
        <div className="container">{children}</div>
      )}
    </div>
  )
}

export default Section
